const CardApp = ({ application }) => {
  const { slug, name, description, url } = application;

  return (
    <div className="col-12 col-md-4 mb-4 d-flex align-items-stretch">
      <div className={`card ${slug}`}>
        <div className="card-body">
          <h1 className="card-title text-capitalize text-truncate">
            <a href={url} className="nounderline stretched-link nounderline">
              {name}
            </a>
          </h1>
          <p className="card-text text-truncate">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default CardApp;
