import React from "react";
import Cookies from "js-cookie";

function NavLink({ nameUser, authServerAccess }) {

  
  const remove = () => {
    const name = process.env.REACT_APP_STORAGE_NAME;
    
    //REACT_APP_LOGIN_URL
    Cookies.remove(name);
  };

  return (
    <div className="nav-item dropdown">
      <a
        className="link-dark text-decoration-none"
        data-toggle="dropdown"
        aria-expanded="true"
        role="button"
        aria-haspopup="true">
        <div className="circle-container">
          <div className="circle-element">{nameUser.charAt(0)}</div>
        </div>
      </a>
      <ul className="dropdown-menu dropdown-menu-end text-small dropLogin ">
        {!!authServerAccess && (
          <>
            <li className="dropdown-item">
              <a
                href={`${process.env.REACT_APP_API_BASE_URL}/dashboard`}
                className="text-decoration-none"
                style={{ display: "inline-block", width: "100%" }}>
                <i className="la la-user"></i>Accesso al Server
              </a>
            </li>
            <li>
              <hr className="dropdown-divider opacity-25" />
            </li>
          </>
        )}

        <li className="dropdown-item">
          <a
            href={`${process.env.REACT_APP_LOGIN_URL}`}
            className="text-decoration-none"
            style={{ display: "inline-block", width: "100%" }}
            onClick={remove}>
            <i className="la la-lock"></i>Esci
          </a>
        </li>
      </ul>
    </div>
  );
}
export default NavLink;
//logout, Dashboard
