import axios from "axios";

import authHelper from "./auth";

const me = async () => {
  const url = `/api/v1/user/me`;
  const auth = authHelper.get();

  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${auth.jwt}`;

  let user = null;
  try {
    const { data } = await axios.get(url);
    user = data;
  } catch (error) {
    authHelper.remove();
    throw error;
  }

  return user;
};

const logout = () => {
  authHelper.remove();
};

const isLogged = () => {
  const user = me();
  return !!user;
};

const userHelper = {
  me,
  logout,
  isLogged,
};

export default userHelper;
