import "./Style/style.scss";
import userHelper from "./helper/user";
import { useState, useEffect } from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
import CardList from "./Components/card-list/cardList";
import Footer from "./Components/footer/Footer";
import Header from "./Components/header/Header";

function App() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameUser, setNameUser] = useState("");
  const [authServerAccess, setAuthServerAccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        var result = await userHelper.me();
        setApplications(result.apps);
        setNameUser(result.name);
        setAuthServerAccess(result.admin);
      } catch (error) {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Header nameUser={nameUser} authServerAccess={authServerAccess} />
      <div id="main-content" className="bg-light">
        <div className="bg-light container pt-5">
          {!!loading ? (
            <div className="row d-flex justify-content-center">
              <div className="spinner-border " role="status" />
            </div>
          ) : !!applications.length ? (
            <CardList applications={applications} />
          ) : (
            <div className="alert alert-warning" role="alert">
              Non sei autorizzato all'utilizzo di nessuna applicazione
            </div>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default App;
