import CardApp from "../cardApp/cardApp";

const CardList = ({ applications }) => (
  <div className="row">
    {applications.map((application) => {
      return <CardApp key={application.id} application={application} />;
    })}
  </div>
);

export default CardList;
